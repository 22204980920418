<template>
	<b-tabs
		vertical
		content-class="col-12 col-md-9 mt-1 mt-md-0"
		pills
		nav-wrapper-class="col-md-3 col-12"
		nav-class="nav-left"
	>
		<!-- Tab: General Information -->
		<b-tab active>
			<template #title>
				<feather-icon
					icon="UserIcon"
					size="18"
					class="mr-50"
				/>
				<span class="font-weight-bold">Datos generales</span>
			</template>

			<account-setting-general class="mt-2 pt-75" />
		</b-tab>

		<!-- Tab: Password -->
		<b-tab>
			<template #title>
				<feather-icon
					icon="LockIcon"
					size="18"
					class="mr-50"
				/>
				<span class="font-weight-bold">Cambiar contraseña</span>
			</template>

			<account-setting-password class="mt-2 pt-75" />
		</b-tab>
	</b-tabs>
</template>

<script>
import AccountSettingPassword from "./AccountSettingPassword.vue"
import AccountSettingGeneral from "./AccountSettingGeneral.vue"

export default {
	components: {
		AccountSettingPassword,
		AccountSettingGeneral
	}
}
</script>
